import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import ContactForm from '../components/estimate-form'

export const StartWithAFreeQuote = (props) => (
  <div className="container mb-4">
    <div
      className="row rounded-lg m-0 overflow-hidden shadow-lg"
      style={{ background: '#fff' }}
    >
      <div
        className={`col-12 col-md-6 col-sm-12 col-lg-6 flex-row items-center p-8 mb-4 sidebarform cardborder`}
      >
        <h2 className="text-center">Start with a Consultation!</h2>
        <ContactForm
          showServicesList={true}
          showLabels={true}
          showPlaceHolders={true}
          pageLocation={
            props.location.href ? props.location.href : 'Not provided'
          }
        />
      </div>
      <div
        className={`col-12 col-md-6 col-sm-12 col-lg-6 p-0 flex flex-row items-center`}
      >
        <GatsbyImage
          image={getImage(props.data.contactFormSideImage)}
          className="block w-full h-full"
        />
      </div>
    </div>
  </div>
)
